import React, { FC } from "react"
import { formatCurrencyString } from "use-shopping-cart"
import { BestillingsLinje } from "./bestil-kurv"
import BestilLinjeButton from "./bestil-linje-button"

interface BestilLinjeSalatProps {
  salat: BestillingsLinje
}

const BestilLinjeSalat: FC<BestilLinjeSalatProps> = ({ salat }) => {
  return (
    <div>
      <div className="flex items-baseline">
        <div className="uppercase">{salat.vare.name}</div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <BestilLinjeButton linje={salat} checkBeforeAdding={true} />
          <div className="text-xs">
            {`${salat.vare.nameDetails} ${formatCurrencyString({
              value: salat.vare.price,
              currency: "DKK",
            })}`}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BestilLinjeSalat
