import React, { FC, useEffect, useState } from "react"
import { useShoppingCart } from "use-shopping-cart"
import theme from "../../css/tailwindTheme.json"
import { BestillingsLinje, Vare } from "./bestil-kurv"
interface BestilLinjeButtonProps {
  linje: BestillingsLinje
  checkBeforeAdding: boolean
}

const BestilLinjeButton: FC<BestilLinjeButtonProps> = ({
  linje,
  checkBeforeAdding,
}) => {
  const {
    addItem,
    cartDetails,
    decrementItem,
    setItemQuantity,
  } = useShoppingCart()
  const [antal, setAntal] = useState(0)
  const [okToAdd, setOkToAdd] = useState(!checkBeforeAdding)

  useEffect(() => {
    setAntal(
      linje.vare.sku in cartDetails ? cartDetails[linje.vare.sku].quantity : 0
    )

    const varer = Object.values(cartDetails) as Vare[]
    if (checkBeforeAdding) {
      setOkToAdd(
        varer.some(
          (vare) =>
            vare.name !== "Dagens salat" &&
            vare.dateString === linje.vare.dateString
        )
      )

      if (
        varer.filter((vare) => vare.dateString === linje.vare.dateString)
          .length > 0 &&
        varer
          .filter((vare) => vare.dateString === linje.vare.dateString)
          .every((vare) => vare.name === "Dagens salat")
      ) {
        setItemQuantity(linje.vare.sku, 0)
      }
    }
  }, [
    cartDetails,
    checkBeforeAdding,
    linje.vare.dateString,
    linje.vare.sku,
    setItemQuantity,
  ])

  return (
    <span
      className={`relative z-0 inline-flex rounded-md ${
        okToAdd ? "" : "pointer-events-none opacity-25"
      }`}
    >
      <button
        type="button"
        disabled={!okToAdd}
        onClick={() => decrementItem(linje.vare.sku)}
        className="relative inline-flex items-center p-2 transition duration-150 ease-in-out bg-opacity-50 border border-gray-300 rounded-l-md hover:text-gray-400 focus:z-10 focus:outline-none active:bg-gray-100 active:text-gray-500"
        style={{
          touchAction: "manipulation",
          backgroundColor:
            antal > 0 ? theme.colors.main.blue : theme.colors.main.smoke,
          color:
            antal > 0 ? theme.colors.main.lightsmoke : theme.colors.main.gray,
        }}
      >
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className="inline-flex items-center justify-center w-12 bg-opacity-50 border border-l-0 font-xl"
        style={{
          backgroundColor:
            antal > 0 ? theme.colors.main.blue : theme.colors.main.smoke,
          color:
            antal > 0 ? theme.colors.main.lightsmoke : theme.colors.main.gray,
        }}
      >
        <pre className="">{antal}</pre>
      </div>
      <button
        type="button"
        disabled={!okToAdd}
        onClick={() => addItem(linje.vare)}
        className="relative inline-flex items-center p-2 -ml-px transition duration-150 ease-in-out bg-opacity-50 border border-gray-300 rounded-r-md hover:text-gray-400 focus:z-10 focus:outline-none active:bg-gray-100 active:text-gray-500"
        style={{
          touchAction: "manipulation",
          backgroundColor:
            antal > 0 ? theme.colors.main.blue : theme.colors.main.smoke,
          color:
            antal > 0 ? theme.colors.main.lightsmoke : theme.colors.main.gray,
        }}
      >
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </span>
  )
}

export default BestilLinjeButton
