import React, { FC } from "react"
import { formatCurrencyString } from "use-shopping-cart"
import { BestillingsLinje } from "./bestil-kurv"
import BestilLinjeButton from "./bestil-linje-button"

interface BestilLinjeProps {
  voksen: BestillingsLinje
  barn: BestillingsLinje
}

const BestilLinje: FC<BestilLinjeProps> = ({ voksen, barn }) => {
  return (
    <div>
      <div className="flex items-baseline">
        <div className="uppercase">{voksen.vare.name}</div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <BestilLinjeButton linje={voksen} checkBeforeAdding={false} />
          <div className="text-xs">
            {`${voksen.vare.nameDetails} ${formatCurrencyString({
              value: voksen.vare.price,
              currency: "DKK",
            })}`}
          </div>
        </div>
        <div>
          <BestilLinjeButton linje={barn} checkBeforeAdding={false} />
          <div className="text-xs">
            {`${barn.vare.nameDetails} ${formatCurrencyString({
              value: barn.vare.price,
              currency: "DKK",
            })}`}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BestilLinje
