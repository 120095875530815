import { format, isFuture } from "date-fns"
import { da } from "date-fns/locale"
import { isNil, splitEvery, take } from "ramda"
import React, { FC } from "react"
import { BestillingsLinje } from "./bestil-kurv"
import Holidays from "date-holidays"
import BestilLinje from "./bestil-linje"
import BestilLinjeSalat from "./bestil-linje-salat"

interface BestilDagProps {
  dag: Date
  linjer: BestillingsLinje[]
}

const hd = new Holidays("DK")

const BestilDag: FC<BestilDagProps> = ({ dag, linjer }) => {
  const muligHelligdag = hd.isHoliday(dag)
  const salat = linjer[4]
  const lukket = linjer.some((linje) => linje.lukket === true)
  const okAtBestille = !lukket && isFuture(dag)

  if (lukket)
    return (
      <div className="flex flex-col p-8 bg-main-gray text-white opacity-50 line-through">
        <div className="mb-4 text-2xl uppercase">
          {format(dag, "eeee do LLLL", { locale: da })}
        </div>
        <div className="font-black uppercase font-dark">Lukket</div>
      </div>
    )

  return (
    <div
      className={`${
        okAtBestille
          ? "bg-main-lightsmoke"
          : "bg-main-gray text-white opacity-50 line-through"
      } flex flex-col p-8`}
    >
      <div className="mb-4 text-2xl uppercase">
        {format(dag, "eeee do LLLL", { locale: da })}
      </div>

      {isNil(linjer[0].vare.name) ? (
        <p>Lukket {muligHelligdag ? "- " + muligHelligdag.name : ""}</p>
      ) : (
        <div className="flex flex-col justify-between h-full">
          <div className="mb-4 leading-tight">
            {linjer
              .filter((linje) => linje.vare.nameDetails.includes("Voksen"))
              .map((linje) => (
                <div key={linje.vare.sku} className="mb-2">
                  <div className="font-black uppercase font-dark">
                    {linje.vare.name}
                  </div>
                  {linje.vare.details && (
                    <div className="tracking-tighter">
                      - {linje.vare.details}
                    </div>
                  )}
                </div>
              ))}
          </div>

          <div className="grid gap-4">
            {okAtBestille &&
              splitEvery(2)(take(4)(linjer)).map(([voksen, barn]) => (
                <BestilLinje
                  key={voksen.vare.sku}
                  voksen={voksen}
                  barn={barn}
                />
              ))}
            {okAtBestille && salat.vare.price > 0 && (
              <BestilLinjeSalat salat={salat} />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default BestilDag
