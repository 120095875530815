import React from "react"

export const KontaktAdresse = () => (
  <address>
    <p className="not-italic text-main-blue">
      <a href="mailto:mad@oeland.nu">mad@oeland.nu</a>
    </p>
    <p className="not-italic">
      <a href="tel:+4540190018">40 19 00 18</a>
    </p>
  </address>
)